<template>
    <div class="d-block ">
  <div class="row">
    <div class="col-9  fs-4  page-heading-parter col-sm-9 col-lg-10">
      <p class="mypracticeMobile" style="margin-left: 20px;">Partner clinics</p>
    </div>
    <div class="col-2 col-sm-3 col-lg-2">
        <div class="my-3 text-center">
    <button  class="btn btn-blue-color text-white" style="border-radius: 10px;" @click="GoSetting()" >Back</button>
  </div>
    </div>
  </div>
  <div class="hr-border" ></div>
</div>
<div class="px-4 ">
  <div class=" mt-3 pb-2" style="margin-top:10px"  v-if="noClinic && clinicConsult.length === 0">
    <h5>No partner clinics </h5>
      </div>
    </div>
  
    <div class="mb-3 ms-3 mt-2" v-if="clinicConsult.length > 0" >
        <label >Name of clinics</label>
      </div>
      
        <div class="row mt-2 ms-2"  v-for="(value, index) in clinicConsult" :key="index">
      
      <div class="col-8 col-lg-4">
        <input type="text"  :disabled="!value.isEditable" :value="value.clinic_name"  autocomplete="off" class="form-control" />
      </div>
      
       <div class="col-3 col-lg-4">
        <button type="button" @click="handleInputEditState(value, index)" style="font-size: 20px" id="btn_schedule_add"
          class="btn btn_edit">
          <font-awesome-icon :icon="['fas', 'edit']" />
        </button>
      </div>


        </div>
   
<div class="my-3 mx-3" v-if="addNewClinicButton">
<button  class="btn btn-blue-color text-white" style="border-radius: 10px;" @click="enableClinic()" >Add new clinic</button>
</div>
<div class="mobileStyleDoctor" v-if="newClinic">
  <div class="d-flex justify-content-end">
    <button type="button" @click="cancelCustom(value, index)" style="font-size: 10px" id="btn_schedule_add"
          class="btn btn_edit fs-3">
          <font-awesome-icon :icon="['fas', 'window-close']" />
        </button>
  </div>
  <div class="row clinicTextAlign" >
    <div class="clinic_section text-align text-left"  >
        <fieldset class="curve-box" style="margin: 0px;padding: 0px 15px 15px 15px">
            <legend class="subHeadingText">Clinic info</legend> 
            <div class="row">
            <div class="col-12">
              <div class="form-group my-2">
                <label class="receiveConsultation">Name<span class="star">*</span> </label>
                <input type="text" v-model="this.clinic_name" class="form-control" name="clinic_name" id="clinic_name"  
                  />
              </div>
            </div>
          </div>
          
          <div class="row">
            <div class="col-12">
              <div class="form-group my-2">
                <label class="receiveConsultation">Address   </label>
                <input  type="text" v-model="this.current_address" class="form-control" name="current_address" id="current_address" >
              </div>
              <div class="form-group my-2">
                <input  type="text" v-model="this.option_address" class="form-control" name="option_address" id="option_address" >
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-6 col-lg-6">
                <div class="form-group my-2">
                  <label class="receiveConsultation">City </label>
                  <input  type="text" v-model="this.city" class="form-control" >
                </div>
              </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-6 col-lg-6">
              <div class="form-group my-2">
                <label class="receiveConsultation">Pincode <span class="star">*</span></label>
                <input type="text" v-model="this.coPincode" maxlength="6" class="form-control" name="pincode"
                   id="pincode"   />
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-8">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" name="is_checked" id="is_checked" v-model="is_checked"  />
            <label class="form-check-label " for="activateORdeactivate">Activate </label>
          </div>
        </div>
      </div>
        </fieldset>
      </div>
    </div>
    <div class="hr-border mt-5"></div>
    <div class="row">
    <div class="col-10 mb-0 col-sm-9 col-lg-10">
      <p class="mypracticeMobile" style="margin-left: 20px;margin-top: 20px;">Clinicians</p>
    </div>
    </div>


    <div class="mb-3  mt-1 ms-2" v-if="clinicianData.length > 0">
        <label >Name of clinicians</label>
      </div>
<div class="my-3 mx-2">
 
  <div class="row mt-2" v-for="(value, index) in clinicianData" :key="index">
      
      <div class="col-8">
        <input type="text"  :disabled="!value.isEditable" :value="value.firstName"  autocomplete="off" class="form-control" />
      </div>
      
       <div class="col-3 ">
        <button type="button" @click="handleDoctorEdit(value, index)" style="font-size: 20px" id="btn_schedule_add"
          class="btn btn_edit">
          <font-awesome-icon :icon="['fas', 'edit']" />
        </button>
      </div>  
    </div>
    </div>
    <div v-if="addNewClinicianButton ">
      
      <button  class="btn btn-blue-color text-white mt-3" style="border-radius: 10px;" @click="enableClinician()" :disabled="!is_checked">Add new clinician</button>
    
</div>
<div class="row clinicTextAlign" v-if="newClinician && is_checked "  >
    <div class="clinic_section text-align text-left" >
      <div class="d-flex justify-content-end">
    <button type="button" @click="cancelClinisian(value, index)" style="font-size: 10px" id="btn_schedule_add"
          class="btn btn_edit fs-3">
          <font-awesome-icon :icon="['fas', 'window-close']" />
        </button>
  </div>
        <fieldset class="curve-box"  style="margin: 0px;padding: 0px 15px 15px 15px">
            <legend class="subHeadingText">Clinician info</legend> 
            <div class="row">
            <div class="col-12 ">
              <label class="receiveConsultation">First name<span class="star">*</span></label>

              <div class="form-group input-group">
        <span class="input-group-addon">Dr.</span>
        <input type="text" class="form-control cursor UpdateTextAlignment" v-model="firstName" >
        </div>
            </div>
          </div>
          
          <div class="row">
            <div class="col-12">
              <div class="form-group my-2">
                <label class="receiveConsultation">Last name<span class="star">*</span></label>
                <input  type="text" v-model="this.lastName" class="form-control">
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-6 col-lg-6">
                <div class="form-group my-2">
                  <label class="receiveConsultation">Mail ID <span class="star">*</span></label>
                  <input  type="text" v-model="this.email" class="form-control" >
                </div>
              </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-6 col-lg-6">
              <div class="form-group my-2">
                <label class="receiveConsultation">Phone + WhatsApp <span class="star">*</span></label>
                <input type="text" v-model="this.mobile" maxlength="10" class="form-control"  />
              </div>
            </div>
          </div>
          <div class="row mt-3">
        <div class="col-8">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" name="isActive" id="isActive" v-model="isActive" />
            <label class="form-check-label " for="activateORdeactivate">Activate </label>
          </div>
        </div>
      </div>
        </fieldset>
      </div>

      </div>
        <div class="clinicSubmitBtn mt-5" >
      <div style="margin-right: 10px;">
          <button class="btn btn-blue-color text-white" @click="editbutton? updateConsultation() : createConsultation()">{{ this.editbutton ? "Update" : "Submit" }}</button>
      </div>
      <div style="margin-left: 10px;">
           <button class="btn btn-color text-white " @click="GoSetting()">Cancel</button>
      </div>
      </div>
</div>
</template>
<script>
import axios from "axios";
export default {
props: ["docData"],
data() {
  return {
    newClinic:false,
    addNewClinicButton:true,
    noClinic:true,
    noClinician:true,
    newClinician:false,
    addNewClinicianButton:true,

    email:'',
    firstName:'',
    mobile:'',
    lastName:'',
    is_checked:false,
    isActive:false,

    coData: {},
    city:"",
    clinicConsult: [],
    clinicianData: [],
    hcpsDoctorsId: '',
    clinic_name: "",
    clinic_id: "",
    current_address:"",
    option_address:"",
    clinicName:"",
    coPincode: "",
    editbutton: false,
    currentlyEditedClinic:{},
    currentlyEditedPincodes: {},
    websiteValidationForm: false,
    payloadArray:[],
    nameRegex:/^[A-Za-z.\s]+$/,
    pincodeValidation:/^[1-9](\s*\d){5}$/,
    mobileValidation:/^[6-9]\d{9}$/,
    numberRegex:/^[1-9][0-9]*$/,
    clinicConsultationData:{},
    clinicUpdate:null
  };
},
computed: {
  
areFieldsClinicEmpty(){
  return (
    !this.nameRegex.test(this.clinic_name) ||
    !this.nameRegex.test(this.city)||
    !this.current_address||
    !this.pincodeValidation.test(this.coPincode)
  );
}
},
methods: {
  clearData(){
    this.clinic_name = '',
    this.current_address = '',
    this.option_address = '',
    this.city = ''
    this.coPincode='',
    this.is_checked=false
  },
  clearDataDoctor(){
    this.firstName = '',
    this.lastName = '',
    this.email = '',
    this.mobile = ''
  },
    enableClinic(){
      this.clinicConsult.forEach(customer => {
        customer.isEditable = false;
      });
      this.noClinician=false
      
      this.noClinic=false
      this.addNewClinicButton=false
       this.newClinic = true;         
       this.clearData();
       this.clearDataDoctor();
    },
    enableClinician(){
        this.newClinician = true
        this.addNewClinicianButton=false
        this.clearDataDoctor();
        this.editbutton = false;
    },
    cancelClinisian(index){
      this.newClinician = false
      this.addNewClinicianButton=true


      this.clinicConsult.forEach((customer, i) => {
        customer.isEditable = i === index; })

    },
     cancelCustom(index){
     window.location.reload()
      this.newClinic =false
      this.addNewClinicButton=true
      

      this.clinicianData.forEach((customer, i) => {
        customer.isEditable = i === index; })

    },
  
  GoSetting() {
    window.scrollTo(0, 0);
    window.location.reload()

    this.newClinic =false
    this.addNewClinicButton=true

    this.$router.push(`/doctor/manage/partner-clinic`);
  },

  handleDoctorEdit(value,index){
    this.clinicianData.forEach((customer, i) => {
    customer.isEditable = i === index; 
  });
  this.addNewClinicianButton=false
    this.updateClinicianData =value
    this.editbutton = true;
    this.newClinician=true
    this.email = value.email;
    this.isActive = value.isActive
    this.firstName =value.firstName;
    this.mobile = value.mobile;
    this.lastName =value.lastName;


   
    // this.clinic_name = value.clinic_name;
    // this.current_address = value.current_address;
    // this.option_address = value.option_address;
    // this.city = value.city;
    // this.coPincode = value.pincode;

    let previousData = [...this.clinicianData]
      previousData[index].isEditable = true;
      this.clinicianData = previousData;
  },

  handleInputEditState(value,index){
    this.clinicConsult.forEach((customer, i) => {
    customer.isEditable = i === index; 
  });
  this.addNewClinicButton=false
    this.editbutton = true;
    this.newClinician=false
    this.clinic_id = value.id
    this.newClinic=true,
    this.is_checked=value.is_checked,

    this.clinic_name = value.clinic_name;
    this.current_address = value.current_address;
    this.option_address = value.option_address;
    this.city = value.city;
    this.coPincode = value.pincode;
    this.clinicUpdate = value
    let previousData = [...this.clinicConsult]
      previousData[index].isEditable = true;
      this.clinicConsult = previousData;
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL

    axios
      .get(`${BASE_API_URL}/hcps`)
      .then((response) => {
        
        if(response.data) {
          const filteredDoctors = response.data.filter((doctors) => {
            return(
              this.clinic_id == doctors.clinic_hcp_id
            )
          })
          this.clinicianData = filteredDoctors
        }
      })
  },
async createConsultation() {
    const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
        if (!this.clinic_name) {
            this.$swal("Please enter the clinic name!");
            return
        }
              
        if (!this.coPincode) {
            this.$swal("Please enter the pincode!");
            return
            }
        if(this.newClinician){

            if (!this.firstName) {
                this.$swal("Please enter the First Name!");
                return
            }
            if (!this.lastName) {
                this.$swal("Please enter the Last Name!");
                return
            }
            if (!this.mobile) {
                this.$swal("Please enter the mobile number!");
                return
            }
            if (!this.validMobile(this.mobile)) {
                return false;
            }
            if (!this.email) {
                this.$swal("Please enter the email ID!");
                return;
            }
            if (!this.validEmail(this.email)) {
                return false;
            }
        }
  let clinicPayload = {
        clinic_name: this.clinic_name,
        pincode: this.coPincode,
        city:this.city,
        current_address:this.current_address,
        option_address:this.option_address,
        is_publish_clinic:false,
        is_active:true,
        is_checked:this.is_checked,
        hcps_id: this.hcpsDoctorsId,
        admin_customer_id:this.coData?.customer?.id,
        role : 'hcp'
      };
      if(this.clinic_id == ''){
      await axios
        .post(`${BASE_API_URL}/clinic-consultation`, clinicPayload)
        .then((response) => {
            if(response.data){
    const payload ={
      firstName : this.firstName,
      lastName : this.lastName,
      email : this.email,
      mobile : this.mobile,
      isActive :this.isActive,

      role:  'hcp',
      updated_by:'admin-customer',
      // clinic_id:response.data.id,
      clinic_id: response.data.id,
      pincode : this.coPincode,
      practiceId:[],
      doctorPatientId:[],
      Doctorconsultaion:[],
      consultation_id:[],
      }
       axios
    .post(`${BASE_API_URL}/hcps`,payload)
    .then(async response =>{
      console.log(response,"response")
    this.$swal("Record is created");
    this.newClinic =false
  this.getClinicConsultation() ;
    }
    )   }
          return response.data
        }
        )
        .catch((error) => {
          return error
          });
        }else{
          const payload ={
      firstName : this.firstName,
      lastName : this.lastName,
      email : this.email,
      mobile : this.mobile,
      role:  'hcp',
      updated_by:'admin-customer',
      // clinic_id:response.data.id,
      isClinicAdministrator:true,
      clinic_id: this.clinic_id ,
      pincode : this.coPincode,
      practiceId:[],
      doctorPatientId:[],
      Doctorconsultaion:[],
      consultation_id:[],
      }
       axios
    .post(`${BASE_API_URL}/hcps`,payload)
    .then(async response =>{
      console.log(response)
    this.$swal("Record is created");
  this.getClinicConsultation() ;
  this.newClinic =false
    }
    ) 
        }
  },

  async updateConsultation() {
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL

          let updateClinicPayload ={
            clinic_name: this.clinic_name,
            city:this.city,
            current_address:this.current_address,
            option_address:this.option_address,
            pincode: this.coPincode,
            is_checked:this.is_checked,

          }
          await  axios
          .put(`${BASE_API_URL}/clinic-consultation/${this.clinicUpdate.id}/update`, updateClinicPayload)
          .then(async (response)=>{
          this.newClinic =true
          window.location.reload()


            if(response.data){
              let reupdatePayload = {
                firstName: this.firstName,
                lastName: this.lastName,
                email: this.email,
                mobile: this.mobile,
                isActive :this.isActive,

                clinic_id: this.clinicUpdate.id,
            };
            await axios
                .put(`${BASE_API_URL}/hcps/${this.updateClinicianData.id}/update`, reupdatePayload, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    if (response?.data) {
                      this.$swal(
                "Updated Successfull!!!"
              ).then(()=>{
              window.location.reload()
              })
                    }
                this.newClinic =true
             })
            }
          }
        )
                .catch(function (error) {
                    console.log(error?.response);
                });
        },

  validMobile(inputtxt){
            const mobilevalidation = /^[6-9]\d{9}$/;
            if(inputtxt.match(mobilevalidation)){
                return true
            }else{
                this.$swal("Please enter the correct mobile number!");
                return false;
            }
        },
    validEmail(inputtxt) {
        var email = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        var consecutiveSpecialChar = /[\W]{2}/;

        if (inputtxt.match(email) && !consecutiveSpecialChar.test(inputtxt)) {
            return true;
        } else {
            this.$swal("Please enter the correct email ID!");
            return false;
        }
    },
  getClinicConsultation() {
    const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
    axios
      .get(`${BASE_API_URL}/clinic-consultation`)
      .then((response) => {
        const filteredValue = response.data.filter((consult) => {
          return(
            this.coData?.customer?.id === consult?.admin_customer_id?.id
          )
        })
        this.clinicConsult = filteredValue;
          const createdData = this.clinicConsult.filter(item => {
              return item.clinician_others_id?.id === this.hcpsDoctorsId && item.role == 'clinician-others'
              });
            this.clinicConsultationData =createdData.length > 0 ? createdData[0] : null;
          this.clinic_name = this.clinicConsultationData.clinic_name,
          this.city = this.clinicConsultationData.city,
          this.coPincode = this.clinicConsultationData.pincode,
          this.current_address =this.clinicConsultationData.current_address
})
  },
  async getClinicianOthersData() {
    const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
    await axios
    .get(`${BASE_API_URL}/hcps/${this.hcpsDoctorsId}/one`)

      .then((response) => {
        this.coData = response.data

      })
  },
  async getDocters(){
    const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
    axios
      .get(`${BASE_API_URL}/hcps`)
    
  },
},
created: async function () {
  let hcpsDoctorID = localStorage.getItem('id');
   this.hcpsDoctorsId = JSON.parse(hcpsDoctorID);
   await this.getClinicianOthersData();
 await this.getClinicConsultation();
 await this.getDocters();
},
};
</script>
<style>
.clinicSubmitBtn{
    display: flex;
    justify-content:center;
}
 fieldset.curve-box {
    border: 2px solid #00979e;
    border-radius: 20px;
    padding: 15px;
    margin-top: 1.5rem;
    }
legend.subHeadingText {
padding: 4px 10px;
margin-bottom: 0px;
display: block;
float: none;
width: auto;
font-size: 20px;
}
.allfieldcontent{
color: red;
margin-top: 15px
}
.star {
  color: red;
  font-size: 20px;
}
.hideOpacity{
opacity: 0;
}
.page-heading-parter{
  color: #00979e;
font-size: 28px;
font-weight: bold;
}
.yes-button,
.no-button {
padding: 10px 20px;
border: none;
border-radius: 4px;
cursor: pointer;
}

.yes-button {
background-color: #38761D;
color: #fff;
}

.no-button {
background-color: #FF9900;
color: #fff;
}
.popup {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: rgba(0, 0, 0, 0.2) !important;
display: flex;
align-items: center;
justify-content: center;
}

.popup-content {
text-align: center;
background: #fff;
width: 400px;
padding: 20px;
border-radius: 8px;
box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
.cross-icon{
font-size:25px;
color: #cd381c;
cursor: pointer;
float: right;
}
.clinicCreateBtn{
color: #00979e !important;
cursor: pointer;
}

button#btn_clinicconsult_add {
  border-color: transparent;
}

.disableCreateBtn{
color:  #dddddd !important;
pointer-events: none;
cursor: auto; 
}
.clinicTextAlign input{
text-align: left !important;;
}

/* .receiveConsultation {
color: #34989f;
}

.checkbox-inline {
color: #34989f;
} */
.checkbox-inline.heading {
font-size: 20px;
margin-bottom: 0px;
}

.my-3.subHeading {
/* color: #34989f; */
font-size: 18px;
}

.ms-5.text-color {
/* color: #34989f; */
font-size: 18px;
}
@media screen and (max-width:991px) {
.mobileStyleDoctor {
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 6rem;
}
.mypracticeMobile {
  margin-left: 20px;
  /* margin-bottom: 10px; */
  margin-top: 1rem;
  color: #000;
  font-weight: 550;
  font-size: 21px;
}
}
</style>
